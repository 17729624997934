.header-container{
    background-color: var(--dark-color);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.header-title{
    color: var(--primary-color);
    text-align: center;
    font-size: 3rem;
    font-style: italic;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}