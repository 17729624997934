.footer-container{
    background-color: #303030;
    border-top: 2px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10px;
    font-size: 1.5rem;
}

.footer-container a{
    text-decoration: none;
    font-weight: 600;
    font-style: italic;
    color: var(--primary-color);
    padding: 5px 10px;
    transition: all .5s;
}

.footer-container a:hover{
    cursor: pointer;
    text-shadow: 1px 1px 1px white;
}