.movies-container{
    background-color: #303030BB;
    margin: 20px 10%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
}

.search-input{
    background-color: #303030;
    margin: 10px 0px;
    padding: 10px;
}

.search-input input{
    display: block;
    margin: auto;
    height: 30px;
    border: 2px solid var(--primary-color);
    background-color: #303030;
    border-radius: 15px;
    padding: 5px 20px;
    font-size: 1rem;
    color: white;
}

.filters-container{
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.filter-item{
    background-color: var(--primary-color);
    padding: 3px 10px;
    border: 2px solid #303030;
    border-radius: 0px 20px 0px 20px;

}

.filter-item label{
    color: white;
    font-weight: 600;
    padding: 3px 10px;
}

.filter-item select{
    background-color: #303030;
    color: white;
    text-align: center;
    min-width: 150px;
    padding: 3px 10px;
    border-radius: 0px 10px 0px 0px;
}

.filter-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.filter-button button{
    background-color: var(--primary-color);
    border: 2px solid #303030;
    padding: 5px 30px;
    color: white;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 1s;
}

.filter-button button:hover{
    background-color: white;
    color: var(--primary-color);
    padding: 5px 50px;
    cursor: pointer;
}